import React from 'react'

import img1 from "../compressedPhotos/IMG_3538.jpg"
import img2 from "../compressedPhotos/IMG_3551.jpg"
import img3 from "../compressedPhotos/IMG_3533.jpg"
import img4 from "../compressedPhotos/IMG_3561.jpg"
import img5 from "../compressedPhotos/IMG_3566.jpg"
import img6 from "../compressedPhotos/IMG_3543.jpg"
import img7 from "../compressedPhotos/IMG_3619.jpg"
import img8 from "../compressedPhotos/IMG_3555.jpg"
import img9 from "../compressedPhotos/IMG_3548.jpg"

const images = [ img1, img2, img3, img4, img6, img5, img7, img8, img9]

// style={/*{display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between", width: '56.5%', height: '280px', marginRight: "20px", marginLeft: "20px"}*/}

const Gallery = props => {
    return (
        <div className="gallery" id="gallery" style={props.lang === "English" ? {direction: "ltr"} : {direction: "rtl"}}>
            <h2>{props.lang === "English" ? "Gallery" : "גלריה"}</h2>
            <span style={{marginBottom: "85px"}}>{props.lang === "English" ? "Kutaisi synagogue" : "בית הכנסת בקוטאיסי"}</span> 
            <div className="image-container-1" >
                {images.map((img, index) => {
                    if (index >= 0 && index < 3){
                        return (
                            <img src={img} key={index} className="gallery-image" />
                        )
                    }
                })}
            </div>
            <div className="image-container-2">
                {images.map((img, index) => {
                    if (index >= 3 && index < 6){
                        return (
                            <img src={img} key={index} className="gallery-image" />
                        )
                    }
                })}
            </div>
            <div className="image-container-3">
                {images.map((img, index) => {
                    if (index >= 6 && index < 9){
                        return (
                            <img src={img} key={index} className="gallery-image" />
                        )
                    }
                })}
            </div>
            <a href="https://www.upwork.com/freelancers/~0163870fd0d1b3aba0"><p className="photome" style={{color: "black"}}>{props.lang === "English" ? "Photos by Eli Shohat" : "תמונות מאלי שוחט"}</p></a>
        </div>
    )
}

export default Gallery
