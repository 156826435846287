import React, { useState } from 'react'
import { HashRouter } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
// import { Divide as Hamburger } from 'hamburger-react'
// import { Squash as Hamburger } from 'hamburger-react'
import { position } from 'stylis'

import { FiHome } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";
import { FiImage } from "react-icons/fi";
import { FiMessageCircle } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import { FaMenorah } from "react-icons/fa";
import { GoLocation } from "react-icons/go"


const FixedHeader = props => {
    const [open, setOpen] = useState(false);

    const [langToggle, setLangToggle] = useState(false)

    const handleLangToggle = () => {
        if (langToggle) {
            setLangToggle(false)
        } else {
            setLangToggle(true)
        }
    }

    const handleMenuClick = () => {
        setOpen(false);
    }

    const handleHebrewSelect = () => {
        props.setLang("Hebrew")
        handleLangToggle()
    }
    const handleEnglishSelect = () => {
        props.setLang("English")
        handleLangToggle()
    }
    const handleHebrewSelectMobile = () => {
        props.setLang("Hebrew")
        handleLangToggle()
        setOpen(false)
    }
    const handleEnglishSelectMobile = () => {
        props.setLang("English")
        handleLangToggle()
        setOpen(false)
    }

    const handleButtonClick = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    // const handleMenuClick = () => {
    //     setOpen(false);
    // }

    return (
        <div style={{marginTop: '0px', position: "fixed", top: "0", zIndex: 15}}>
            {window.innerHeight <= window.innerWidth ?(
                <div className="fixed-header-split" style={props.lang === "English" ? {direction: "ltr"} : {direction: "rtl"}}>
                <div  className="fixed-header" style={props.lang === "English" ? {direction: "ltr", left: 0} : {direction: "rtl", right: 0}}>
                    <HashRouter style={{}}>
                        <Link to="#slider" className="link"><h3 className="header-link">{props.lang === "English" ? "JewishKutaisi" : " קוטאיסי יהודית"}{" "}
                            <FaMenorah style={{width: "20px", height: "20px", color: "white"}} />
                        </h3></Link>
                        {/* <Link to="#slider" className="link"><h3 className="header-link">{props.lang === "English" ? "Home" : "דף הבית"}</h3></Link> */}
                        <Link to="#about" className="link"><h3 className="header-link">{props.lang === "English" ? "About" : "על אודות"}</h3></Link>
                        <Link to="#gallery" className="link"><h3 className="header-link">{props.lang === "English" ? "Gallery" : "גלריה"}</h3></Link>
                        <Link to="#contact" className="link"><h3 className="header-link">{props.lang === "English" ? "Contact" : "צור קשר"}</h3></Link>
                        <Link to="#location" className="link"><h3 className="header-link">{props.lang === "English" ? "Location" : "מיקום"}</h3></Link>
                    </HashRouter>
                </div>
                <div  className="fixed-header-2" style={props.lang === "English" ? {direction: "rtl", right: 0, justifyContent: 'right'} : {direction: "ltr", left: 0, justifyContent: 'left'}}>
                    <div style={{display: "block"}}>
                        <h3 style={{ borderRadius: "7px", border: "1px solid white", cursor: "pointer", padding: "2px 5px", textAlign: "center", color: "white", marginRight: '20px', marginLeft: '20px'}} onClick={handleLangToggle}>{langToggle ? <FiChevronUp /> : <FiChevronDown/>}{props.lang === "English" ? "English" : "עברית"}</h3>
                        <div style={langToggle ? {backgroundColor: "white", borderRadius: "7px", position:"absolute", display:"block", top: "65px"} : {display: "none"}}>
                            <h4 onClick={handleEnglishSelect} style={{margin: 0, padding: "5px 15px"}} className="lang-option">English</h4>
                            <div style={{height: "1px", width: "90%", backgroundColor: "black", margin: "auto"}}/>
                            <h4 onClick={handleHebrewSelect} style={{margin: 0, padding: "5px 15px"}} className="lang-option">עברית</h4>
                        </div>
                    </div>
                    <div>
                        <HashRouter style={{}}>
                            <Link to="#contact" className="link">
                                {/* <h4 className="header-link" style={{color: "white", margin: "0px 20px"}}>For catering: +995 571 08 08 48</h4> */}
                                <h4 className="header-link" style={{color: "white", margin: "0px 20px"}}>{props.lang === "English" ? "For catering: +995 571 08 08 48" : "+995 571 08 08 48 :לקייטרינג"}</h4>
                            </Link>
                        </HashRouter>
                    </div>
                </div>
                </div>
            ) : (
                <div>
                    <div className="fixed-header-mobile" style={{padding: '5px'}}>
                        <HashRouter style={{}}>
                            <Link to="#slider" ><h3 className="header-link" onClick={handleMenuClick}>{'JewishKutaisi'/*props.lang === "English" ? "JewishKutaisi" : " קוטאיסי יהודית"*/}{" "}
                                <FaMenorah style={{width: "20px", height: "20px", color: "white"}} />
                            </h3></Link>
                        </HashRouter>
                        <button onClick={handleButtonClick} className={open ? "hamburger hamburger--magnetic active" : "hamburger hamburger--magnetic"} type="button">
                            <div className="inner">
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                        </button>
                    </div>
                        <div
                            className={open ? "card active" : "card"} 
                            style={{
                                position: "fixed",
                                width: "100vw",
                                height: "100vh",
                                backgroundColor: "white",
                                zIndex: 14
                            }}
                        >
                            <div style={props.lang === "English" ? {height: "100%",width: "100%",display: "flex",flexDirection: "column",marginTop: "70px",} : 
                            {height: "100%",width: "100%",display: "flex",flexDirection: "column",marginTop: "70px", direction: "rtl"}}>
                                <HashRouter style={{}}>
                                    <div style={{display: "block", position: "relative", marginLeft: "15px", marginRight: "15px", marginTop: '30px'}}>
                                        <div style={{ borderRadius: "7px", border: "1px solid black", cursor: "pointer", padding: "5px",  color: "black", width: "100px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}} onClick={handleLangToggle}>{props.lang === "English" ? (<h2 style={{margin: 0}}>English</h2>) : (<h2 style={{margin: 0}}>עברית</h2>)}{langToggle ? <FiChevronUp  /> : <FiChevronDown />}</div>
                                        <div style={langToggle ? {backgroundColor: "black", borderRadius: "7px", position:"absolute", display:"block", top: "50px"} : {display: "none"}}>
                                            <h2 onClick={handleEnglishSelectMobile} style={{margin: 0, padding: "5px 15px", color: "white"}} className="lang-option">English</h2>
                                            <div style={{height: "1px", width: "90%", backgroundColor: "white", margin: "auto", color: "white"}}/>
                                            <h2 onClick={handleHebrewSelectMobile} style={{margin: 0, padding: "5px 15px", color: "white"}} className="lang-option">עברית</h2>
                                        </div>
                                    </div>
                                    <Link to="#slider">
                                        <div 
                                            style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "15px"}}
                                            onClick={handleMenuClick}
                                        >
                                            <FiHome style={{width: "45px", height: "45px", color: "black"}} />
                                            <h3 style={{color: "black", margin: "15px", fontSize: "2rem", textDecoration: "underline", textDecorationColor: "black"}} className="header-link">{props.lang === "English" ? "Home" : "דף הבית"}</h3>
                                        </div>
                                    </Link>
                                    <Link to="#about" >
                                        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "15px"}} onClick={handleMenuClick}>
                                            <FiInfo style={{width: "45px", height: "45px", color: "black"}} />
                                            <h3 style={{color: "black", margin: "15px", fontSize: "2rem", textDecoration: "underline", textDecorationColor: "black"}} className="header-link">{props.lang === "English" ? "About" : "על אודות"}</h3>
                                        </div>
                                    </Link>
                                    <Link to="#gallery" >
                                        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "15px"}} onClick={handleMenuClick}>
                                            <FiImage style={{width: "45px", height: "45px", color: "black"}}/>
                                            <h3 style={{color: "black", margin: "15px", fontSize: "2rem", textDecoration: "underline", textDecorationColor: "black"}} className="header-link">{props.lang === "English" ? "Gallery" : "גלריה"}</h3>
                                        </div>
                                    </Link>
                                    <Link to="#contact">
                                        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "15px"}} onClick={handleMenuClick}>
                                            <FiMessageCircle style={{width: "45px", height: "45px", color: "black"}}/>
                                            <h3 style={{color: "black", margin: "15px", fontSize: "2rem", textDecoration: "underline", textDecorationColor: "black"}} className="header-link">{props.lang === "English" ? "Contact Info / Catering" : "קייטרינג / צור קשר"}</h3>
                                        </div>
                                    </Link>
                                    <Link to="#location">
                                        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "15px"}} onClick={handleMenuClick}>
                                            <GoLocation style={{width: "45px", height: "45px", color: "black"}}/>
                                            <h3 style={{color: "black", margin: "15px", fontSize: "2rem", textDecoration: "underline", textDecorationColor: "black"}} className="header-link">{props.lang === "English" ? "Location" : "מיקום"}</h3>
                                        </div>
                                    </Link>
                                    
                                    {/* <div 
                                            style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margin: "15px"}}
                                            onClick={handleMenuClick}
                                        >
                                            <h2 style={{color: "black", margin: "15px", }} >{props.lang === "English" ? "Kosher meals can be arranged for and served at the synagogue." : "ניתן לארגן ולהגיש ארוחות כשרות בבית הכנסת."}</h2>
                                        </div> */}
                                </HashRouter>
                            </div>
                        </div>
                </div>
            )}
        </div>
    )
}

export default FixedHeader
