import React, {useState} from 'react'
import SliderData from '../landscapeComponents/SliderData'
import {FaArrowAltCircleRight, FaArrowAltCircleLeft, FaSlideshare} from 'react-icons/fa'
import { next } from 'stylis'

const MobileImageSlider = props => {
    const [current, setCurrent] = useState(0)
    const length = SliderData.length

    // let myVar = setTimeout(function(){ setCurrent(current === length - 1 ? 0 : current + 1); }, 3000);

    const nextSlide = () => {
        // clearTimeout(myVar);
        // console.log("stopped")
        setCurrent(current === length - 1 ? 0 : current + 1);
        // myVar = setTimeout(function(){ setCurrent(current === length - 1 ? 0 : current + 1); }, 3000);

    }

    const prevSlide = () => {
        // clearTimeout(myVar);
        setCurrent(current === 0 ? length - 1 : current - 1);
        // myVar = setTimeout(function(){ setCurrent(current === length - 1 ? 0 : current + 1); }, 3000);

    }

    if (!Array.isArray(SliderData) || FaSlideshare.length <= 0) {
        return null;
    }
    

    return (
        <section className="slider-mobile" id="slider">
            <FaArrowAltCircleLeft className='left-arrow-mobile' onClick={prevSlide} />
            <FaArrowAltCircleRight className='right-arrow-mobile' onClick={nextSlide} />
            {SliderData.map((slide, index) => {
                return (
                    <div className={index === current ? 'slide active' : 'slide'} key={index}>
                        {index === current && (
                            <img src={slide} className="image" />
                        )}
                    </div>
                ) 
            })}
            <div className="numberIndicator-mobile">
                {SliderData.map((slide, index) => {
                    return (
                        <div style={index === current ? {height: '20px', width: '20px', borderRadius: '50px', backgroundColor: 'black', marginRight: '5px'} : {height: '15px', width: '15px', borderRadius: '50px', backgroundColor: 'white', border: '1px', borderColor:"black", marginRight: '5px'}}></div>
                    )
                })}
            </div>
        </section>
    )
}

export default MobileImageSlider