import React from 'react';


export default function Arrow(props) {
    return (
        <div onClick={props.handleClick} className="arrow" style={props.direction === "left" ?
            {width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50px", position: 'absolute', backgroundColor: "white", left: "20px", zIndex: 3} :
            {width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50px", position: 'absolute', backgroundColor: "white", right: "20px", zIndex: 3}
        }>
            {props.direction === "right" ? ">" : "<"}
        </div>
    );
}