import React from 'react';
import Arrow from './Arrow';

export default class Slider extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            index: 0,
            interval: null
        }

        this.nextImage = this.nextImage.bind(this);
        this.prevImage = this.prevImage.bind(this);
        this.startInterval = this.startInterval.bind(this);
    }

    nextImage() {
        if(this.state.index === this.props.images.length - 1) {
            this.setState({index: 0});
        } else {
            this.setState({index: this.state.index + 1})
        }
    }

    prevImage() {
        if(this.state.index === 0) {
            this.setState({index: this.props.images.length - 1});
        } else {
            this.setState({index: this.state.index - 1});
        }
    }

    startInterval() {
        window.setInterval(this.nextImage, 3000);
    }
    

    render() {
        return (
            <div className="slider" style={{width: "100%", minHeight:"100vh", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", position: 'relative'}}>
                {this.startInterval()}
                <Arrow direction="left" handleClick={this.prevImage} />
                <div style={{width: '100%', position: "absolute", zIndex: 1}}>
                    <img style={{width: "100%", overflow: "hidden"}} src={this.props.images[this.state.index]} alt={this.props.images[this.state.index]} />
                </div>
                <Arrow direction="right" handleClick={this.nextImage}/>
            </div>
        );
    }
}