import React from 'react'

import { Router } from 'react-router'

import { MdCall } from "react-icons/md"
import { MdMailOutline } from "react-icons/md"
import { MdLocationOn } from "react-icons/md"
import { HashRouter, Link } from 'react-router-dom'

import img from "../shulKutaisi/IMG_3619.jpg"

const ContactInfo = props => {
    return (
        <div className="contact-all" id="contact" style={props.lang === "English" ? {direction: "ltr"} : {direction: "rtl"}}>
            <div className="contact" style={{display: 'flex', flexDirection: "column", justifyContent:'center', alignItems: "center"}}>
                <h1 style={{fontWeight: 'bold', marginBottom: '0px'}}>{props.lang === "English" ? "For information related to visiting the synagogue or kosher catering contact Yosef by phone or WhatApp:" : "למידע הקשור לביקור בבית הכנסת או קייטרינג כשר צרו קשר עם יוסף בטלפון או בוואטסאפ:"}</h1>
                {/* <h3 style={{marginTop: '10px', marginBottom: '40px'}}>{props.lang === "English" ? "Kosher meals can be arranged and served by staff connected to the synagogue." : "ניתן לארגן ולהגיש ארוחות כשרות בבית הכנסת."}</h3> */}
                <div style={{
                    width: '100%', 
                    // backgroundColor: "#de9931",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginBottom: "20px"
                }}>
                    <div className="contact-data-segment-parent">
                        <MdCall style={{color: "black", height: "50px", width: "50px"}}/>
                        <div className="contact-data-segment-child">
                            <h1 style={{margin: 0}}>{props.lang === "English" ? "Phone" : "טלפון"}</h1>
                            <span>{props.lang === "English" ? '+995 571 08 08 48' : '48 08 08 571 995+'}</span>
                            <br/>
                            <span>{props.lang === "English" ? '(Georgian or Russian language preferred)': '(עדיפות לשפה גאורגית או רוסית)'}</span>
                        </div>
                    </div>
                    {/* <div className="contact-data-segment-parent">
                        <MdMailOutline style={{color: "black", height: "50px", width: "50px"}} />
                        <div className="contact-data-segment-child">
                            <h1 style={{margin: 0}}>{props.lang === "English" ? "Email" : "אימייל"}</h1>
                            <span>email@email.com</span>
                        </div>
                    </div> */}
                    <div className="contact-data-segment-parent">
                        <MdLocationOn style={{color: "black", height: "50px", width: "50px"}} />
                        <div className="contact-data-segment-child">
                            <a href="https://www.google.com/maps/place/Synagogue/@42.2741195,42.7069433,17z/data=!3m1!4b1!4m5!3m4!1s0x405c8c5820893115:0xb536b6d1f77ab8fb!8m2!3d42.2741195!4d42.709132">
                                <h1 style={{margin: 0, color: "black"}}>{props.lang === "English" ? "Location" : "מיקום"}</h1>
                                <span style={{color: "black"}}>{props.lang === "English" ? 'Boris Gaponov Street, Kutaisi, Georgia' : "רחוב בוריס גאפונוב, קוטאיסי, גיאורגיה"}</span>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <h4>{props.lang === "English" ? "Contact can be done by phone or by whatsapp" : "ניתן ליצור קשר בטלפון או בוואטסאפ"}</h4> */}
            </div>
            
        </div>
    )
}

export default ContactInfo
