import React from 'react'

import img1 from "../compressedPhotos/IMG_3538.jpg"
import img2 from "../compressedPhotos/IMG_3551.jpg"
import img3 from "../compressedPhotos/IMG_3533.jpg"
import img4 from "../compressedPhotos/IMG_3561.jpg"
import img5 from "../compressedPhotos/IMG_3566.jpg"
import img6 from "../compressedPhotos/IMG_3543.jpg"
import img7 from "../compressedPhotos/IMG_3619.jpg"
import img8 from "../compressedPhotos/IMG_3555.jpg"
import img9 from "../compressedPhotos/IMG_3548.jpg"
import img10 from '../compressedPhotos/IMG_3530.jpg'

const images = [ img1, img2, img5, img10, img4, img6, img3, img8, img9, img7]

// style={/*{display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between", width: '56.5%', height: '280px', marginRight: "20px", marginLeft: "20px"}*/}

const MobileGallery = props => {
    return (
        <div className="gallery-mobile" id="gallery">
            <h2>{props.lang === "English" ? "Gallery" : "גלריה"}</h2>
            <div style={{ width: "100%", textAlign: "center",marginBottom: "85px",}}>
                <span style={{ width: '100%'}}>{props.lang === "English" ? "Kutaisi synagogue" : "בית הכנסת בקוטאיסי"}</span> 
            </div>
            <div className="image-container-mobile" style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: "100%", alignItems: "center"}} >
                {images.map((img, index) => {
                    return (
                        <img src={img} key={index} className="gallery-image-mobile" />
                    )
                })}
            </div>
            <div style={{ width: "100%", textAlign: "center"}}>
                <a href="https://www.instagram.com/shohateli/"><p style={{color: "black"}}>{props.lang === "English" ? "Photos by Eli Shohat" : "תמונות מאלי שוחט"}</p></a>
            </div>
        </div>
    )
}

export default MobileGallery
