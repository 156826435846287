import './App.css';
import Slider from './Slider/Slider';
import React, { useState } from 'react';

// landscape components
import ImageSlider from './landscapeComponents/ImageSlider';
import FixedHeader from './landscapeComponents/FixedHeader';
import Text1 from './landscapeComponents/Text1';
import Quote from './landscapeComponents/Quote';
import Gallery from './landscapeComponents/Gallery';
import ContactInfo from './landscapeComponents/ContactInfo';
import Footer from './landscapeComponents/Footer';
import Location from './landscapeComponents/Location';

// mobile components
import MobileImageSlider from './mobileComponents/MobileImageSlider';
import MobileText1 from './mobileComponents/MobileText1';
import MobileGallery from './mobileComponents/MobileGallery';
import MobileContactInfo from './mobileComponents/MobileContactInfo';

function App() {
  // window.addEventListener("orientationchange", function(event) {
  //   window.location.reload();
  // });
  const [lang, setLang] = useState("English")

  React.useEffect(() => {
    console.log("event fired")
    // function handleResize() {
    //   window.location.reload();
    // }
    // window.addEventListener('orientationchange', handleResize)
    window.addEventListener("orientationchange", function() {
      window.location.reload();
    }, false);
  })


  return (
    <div className="App">
      {window.innerHeight <= window.innerWidth ? <FixedHeader lang={lang} setLang={setLang}/> : <FixedHeader lang={lang} setLang={setLang}/>}
      {window.innerHeight <= window.innerWidth ? <ImageSlider /> : <MobileImageSlider />}
      {/* {window.innerHeight <= window.innerWidth ? <Quote /> : <Quote />} */}
      {window.innerHeight <= window.innerWidth ? <Text1 lang={lang}/> : <MobileText1 lang={lang}/>}
      {window.innerHeight <= window.innerWidth ? <Gallery lang={lang}/> : <MobileGallery lang={lang}/>}
      {window.innerHeight <= window.innerWidth ? <ContactInfo lang={lang}/> : <MobileContactInfo lang={lang}/>}
      {window.innerHeight <= window.innerWidth ? <Location lang={lang}/> : <Location lang={lang}/>}
      {window.innerHeight <= window.innerWidth ? <Footer lang={lang}/> : <Footer lang={lang}/>}
    </div>
  );
}

export default App;
