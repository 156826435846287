import React from 'react'

const Footer = (props) => {
    return (
        <div className={window.innerHeight <= window.innerWidth ? 'footer' : 'footer-mobile'}>
            <a href="https://www.upwork.com/freelancers/~0163870fd0d1b3aba0">
                <h4 className="websiteme" style={window.innerHeight <= window.innerWidth ? {fontWeight: "normal"} : {fontSize: "13px", fontWeight: "normal"}}>{props.lang === "English" ? 'Website made by Eli Shohat' : 'האתר נבנה על ידי אלי שוחט'}</h4>
            </a>
        </div>
    )
}

export default Footer
