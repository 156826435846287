import img1 from "../compressedPhotos/IMG_3561.jpg"
import img2 from "../compressedPhotos/IMG_3555.jpg"
import img3 from "../compressedPhotos/IMG_3538.jpg"

const SliderData = [
    img1,
    img2,
    img3,
]

export default SliderData